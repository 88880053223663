exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-[id]-id-2-js": () => import("./../../../src/pages/product/[id]/[id2].js" /* webpackChunkName: "component---src-pages-product-[id]-id-2-js" */),
  "component---src-pages-product-[id]-index-js": () => import("./../../../src/pages/product/[id]/index.js" /* webpackChunkName: "component---src-pages-product-[id]-index-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-technical-js": () => import("./../../../src/pages/technical.js" /* webpackChunkName: "component---src-pages-technical-js" */)
}

